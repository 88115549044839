.franchise {
    position: relative;
    margin-top: 220px;
    @media (max-width: 576px ) {
        margin-top: 120px;
    }
    .get-franchise {
        -webkit-box-shadow: 5px 11px 24px -8px rgba(170,170,170,1);
        -moz-box-shadow: 5px 11px 24px -8px rgba(170,170,170,1);
        box-shadow: 5px 11px 24px -8px rgba(170,170,170,1);
    }
    .bg-path-right {
        position: absolute;
        right: 0;
        bottom: 10%;
        z-index: -1;
    }
}