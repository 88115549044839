html {
  scroll-behavior: smooth;
}

.OwanAFranchise {
  .parent-items {
    height: 280px;
    .FranschiseSummary {
      overflow: hidden;
      height: 145px;
      .clipy-path {
        transition: all 0.3s ease-in-out;
        opacity: 0;
        clip-path: polygon(81% 56%, 100% 41%, 100% 93%, 78% 73%);
        background-color: #fbed1b;
        width: 100px;
        height: 100px;
        position: absolute;
        right: 15px;
        top: 120px;
      }
      -webkit-box-shadow: 5px 11px 24px -8px rgba(170, 170, 170, 1);
      -moz-box-shadow: 5px 11px 24px -8px rgba(170, 170, 170, 1);
      box-shadow: 5px 11px 24px -8px rgba(170, 170, 170, 1);
      transition: all 0.5s ease-in-out;
      img {
        height: 43px;
      }
      &:hover {
        height: 230px;
        transition: all 0.5s ease-in-out;
        small {
          transition: all 5s ease-in-out;
        }
        .clipy-path {
          transition: all 0.5s ease-in-out;
          opacity: 1;
        }
      }
      small {
        opacity: 1;
      }
    }
  }

  .FormFranchise {
    @media (max-width: 576px) {
      margin-top: 90px;
    }

    form {
      h6 {
        font-size: 18px;
      }
    }
  }
}

.MuiStepper-root {
  padding-top: 0 !important;
}

.MuiSvgIcon-root {
  width: 2em !important;
  height: 2em !important;
}

.MuiStepLabel-label.MuiStepLabel-active,
.MuiStepLabel-label {
  font-size: 1.5em !important;
}

.MuiStepConnector-alternativeLabel {
  left: calc(-36% + 20px) !important;
}

.MuiButtonBase-root:hover {
  box-shadow: unset !important;
  transition: unset !important;
}

.MuiStepLabel-iconContainer {
  font-size: 2rem;
}

.email-whatsapp-fontsize {
  font-size: 1.5rem !important;
}

.tripadvisor-a {
  background: #000;
  width: 29px;
  height: 29px;
  display: block;
  border-radius: 50%;
}
.tripadvisor-icon {
  color: #fff;
  width: 24px;
  height: 24px;
  padding-left: 0.2rem;
}

button {
  outline: none !important;
}

.stepone {
  .bg-rounded {
    position: absolute;
    left: -31rem;
    z-index: -1;
  }

  .right-one {
    right: -31rem;
    left: unset;
  }
}

@media (min-width: 701px) {
  .steptwo {
    .h4.border-heading {
      margin-top: 3rem;
    }
  }
}

@media (max-width: 700px) {
  .MuiStepper-root {
    padding-top: 4rem !important;
    padding-left: 0 !important;
  }
  .MuiSvgIcon-root {
    width: 1em !important;
    height: 1em !important;
  }

  .MuiStepLabel-label.MuiStepLabel-active,
  .MuiStepLabel-label {
    font-size: 0.9em !important;
  }

  .MuiStepLabel-iconContainer {
    padding-right: 0 !important;
  }

  .MuiStepButton-root {
    padding-left: 0.8rem !important;
  }

  .stepone {
    text-align: center;

    ul {
      text-align: left;
    }

    iframe {
      width: 100% !important;
    }

    img {
      margin: 1rem 0;
    }
  }
}

.top-steps-btn-wizard-form {
  position: relative;
  top: -2rem;
  text-align: center;
}

.quick-lang-fix {
  position: relative;
  bottom: 9.3rem;
  left: 33%;
}
