.NavbarWowmix {
    a {
        color: #ddd;
        &:hover {
            color: #FBED1B;
            text-decoration: none;
        }
    }
    .logo-style {
        height : 55px;

    }
    
}
.logo-icon-menu-mobile {
    height: 55px;
}

.menu-mobile-screen {
    // background: url(../../../img/bg-header.svg) no-repeat center center;
    // background-size: cover;
    height: 100vh;
    width: 250px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    height: 100%;
    z-index: 999;
    -webkit-box-shadow: 10px 10px 40px -18px rgba(0,0,0,0.75);
-moz-box-shadow: 10px 10px 40px -18px rgba(0,0,0,0.75);
box-shadow: 10px 10px 40px -18px rgba(0,0,0,0.75);
    ul {
        li {
            margin-bottom: 20px;
            a {
                color: #000;
            }
        }
    }
    img {
        right: 0;
        position: absolute;
    }
}