.about {
    margin-top: 170px;
    @media (max-width: 576px) {
        margin-top: 90px;
    }
    .bg-video {
        position: relative;
        height: 220px;
        @media (max-width: 576px) {
            height: 160px;
            margin-top: 100px;
        }
        img:first-of-type {
            position: absolute;
            top: -25%;
            left: 25%;
            border-radius: 100%;
            width: 340px;
            height: 340px;
            @media (max-width: 576px) {
                top: -25%;
                width: 240px;
                height: 240px;
                left: 16%;
            }
        }
        img:last-of-type {
            cursor: pointer;
            position: absolute;
            top: 35%;
            left: 50%;
            margin-left: -20px;
            @media (max-width: 576px) {
                top: 30%;
                left: 48%;
           }
        }
    }
}