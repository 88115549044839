.gallery {
    position: relative;
    margin-top: 120px;
    @media (max-width: 576px) {
        margin-top: 60px;
    }
    .bg-rounded {
        position: absolute;
        bottom: -120px;
        z-index: -1;
        left: -20%;
        width: 550px;
        @media (max-width: 576px) {
            left: -70%;
            top: 25% !important;
            bottom: unset;
            width: 450px;
        }
    }
    .photos {
        .photo-item {
            img {
                width: 100%;
                border-radius: 0 30px 0 30px;
            }
            .tall {
                height: 240px;
            }
            .short {
                // margin-top: -50px;
                height: 190px;
            }
        }
        
       
    }
    
    
}