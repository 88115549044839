@font-face {
  font-family: "open-sans";
  src: url(../src/fonts/OpenSans-Regular.ttf);
}

html {
  font-family: "open-sans";
}

body {
  overflow-x: hidden;
}
.nav-item {
  a {
    &:hover {
      cursor: pointer;
    }
  }
}
.border-heading::before {
  content: "";
  border: 1px solid #fbed1b;
  display: inline-block;
  width: 30px;
  height: 3px;
  background-color: #fbed1b;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #fbed1b !important;
  background-color: #fbed1b !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #fbed1b;
}
.nav-pills .nav-link,
.nav-pills .show > .nav-link {
  color: #000;
}

.btn-dark {
  border-radius: 0 20px 0 20px !important;
}
.btn-warning {
  background-color: #fbed1b !important;
  border-color: #fbed1b !important;
  border-radius: 0 20px 0 20px !important;
  &:focus {
    border-color: #fbed1b;
  }
}

.text-warning {
  color: #fbed1b !important;
}

.bg-warning {
  background: #fbed1b !important;
  border-color: #fbed1b !important;
}

button {
  padding: 10px 0 10px 30px !important;
  font-size: 14px !important;
  transition: all 0.2s ease-in-out;
  svg {
    margin-left: 10px;
  }
  &:hover {
    -webkit-box-shadow: 5px 11px 24px -8px rgba(170, 170, 170, 1);
    -moz-box-shadow: 5px 11px 24px -8px rgba(170, 170, 170, 1);
    box-shadow: 5px 11px 24px -8px rgba(170, 170, 170, 1);
    transition: all 0.2s ease-in-out;
    svg {
      margin-left: 30px;
      transition: all 0.2s ease-in-out;
    }
  }
}
.btn-warning {
  padding: 10px 20px 10px 30px !important;
  font-size: 14px !important;
  transition: all 0.2s ease-in-out;
  svg {
    margin-left: 10px;
  }
  &:hover {
    -webkit-box-shadow: 5px 11px 24px -8px rgba(170, 170, 170, 1);
    -moz-box-shadow: 5px 11px 24px -8px rgba(170, 170, 170, 1);
    box-shadow: 5px 11px 24px -8px rgba(170, 170, 170, 1);
    transition: all 0.2s ease-in-out;
    svg {
      margin-left: 30px;
      transition: all 0.2s ease-in-out;
    }
  }
}

.links-contact {
  li {
    margin-bottom: 10px;
    a {
      color: #fff;
      img {
        border: 1px solid transparent;
        transition: all 0.5s ease-in-out;
        &:hover {
          border-radius: 50%;
          border: 1px solid #000;
        }
      }
    }
  }
}

.form-control {
  border: 1px dashed #ccc;
}

.form-control:focus {
  outline: none;
  border-color: #000;
  box-shadow: none;
}

svg {
  transition: all 0.2s ease-in-out;
}

.all-unset {
  all: unset;
}

//  mobile
@media (max-width: 576px) {
  .body {
    overflow-x: hidden;
  }
}

// spinner

.lds-dual-ring {
  display: flex;
  width: 100%;
  height: 80px;
  justify-content: center;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid yellow;
  border-color: yellow transparent yellow transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.red-text {
  color: red;
  font-weight: bold;
  font-size: 12px;
  margin: 0;

  &:first-of-type {
    margin-top: 10px;
  }
}