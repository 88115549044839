.header {
  background: url(../../../img/bg-header.svg) no-repeat center center;
  background-size: cover;
  height: 100vh;
  .swiper-slide-next {
    transform: translate3d(-80%, 0px, -100px) rotateX(0) rotateY(0) !important;
  }
  .swiper-slide-prev {
    transform: translate3d(80%, 0, -100px) rotateX(0deg) rotateY(0) !important;
  }
  .swiper-slide-shadow-right,
  .swiper-slide-shadow-left {
    background-image: none;
  }
  .swiper-pagination {
    position: unset !important;
  }
  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    background: #000;
  }
  .swiper-pagination-bullet-active {
    background: #000;
}
  
  .slider-header {
    img {
      height: 370px;
      width: 370px;
      border-radius: 100%;
      @media (max-width: 576px) {
        margin: 0 auto !important;
        height: 240px;
        width: 240px;
    }
    }
  }
   a {
     @media (max-width: 576px) {
       margin: 0 auto !important;
     }
   }
}
