.department {
    margin-top: 130px;
    @media (max-width: 576px) {
        margin-top: 80px;
    }
    .button-products {
        height: 200px;
    }
    .product-item {
        transition: all .5s ease-in-out;
        border-radius: 0 20px 0 20px;
        position: relative;
        img {
            border-radius: 0 20px 0 20px;
            height: 200px;
        }
        .heading-product {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: 0 20px 0 20px;
            background-color: #FBED1B !important;
            h4 {
                font-weight: normal;
            }
        }
        &:hover {
            cursor: pointer;
            margin-top: -20px;
            transition: all .5s ease-in-out;
            -webkit-box-shadow: 13px 18px 34px -8px rgba(170,170,170,1);
            -moz-box-shadow: 13px 18px 34px -8px rgba(170,170,170,1);
            box-shadow: 13px 18px 34px -8px rgba(170,170,170,1);
        }
    }
}