.AboutUs {
    position: relative;
    .art_path_left {
        position: absolute;
        left: 0;
        top: 30%;
        z-index: -1;
    }
    .desc-about {
        ul {
            li {
                margin-bottom: 15px !important;
            }
        }
    }
    
}