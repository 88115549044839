.footer {
    background: url(../../../img/bg-footer.svg) no-repeat center center ;
    background-size: cover;
    width: 100%;
    min-height: 400px;
    padding-top: 170px;
    .center-footer {
        img {
            width: 100px;
        }
    }
    .social {
        ul {
            li {
                a {
                    color: #fff;
                    img {
                        border: 1px solid transparent;
                        transition: all .5s ease-in-out;
                        &:hover {
                            border-radius: 50%;
                            border: 1px solid #fff;
                        }
                    }
                }
            }
        }
    }
    .site-map {
        ul {
            li {
                a {
                    transition: all .5s ease-in-out;
                    color: #ddd;
                    font-size: 14px;
                    &:hover {
                        color: #FBED1B;
                        margin-left: 10px;
                        transition: all .5s ease-in-out;
                    }
                   
                }
            }
        }
    }
    
}