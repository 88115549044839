.products {
  .product-summiry {
    transition: all 0.5s ease-in-out;
    position: relative;
    border-radius: 20px;
    &:hover {
      -webkit-box-shadow: 7px 11px 17px -8px rgba(170, 170, 170, 1);
      -moz-box-shadow: 7px 11px 17px -8px rgba(170, 170, 170, 1);
      box-shadow: 7px 11px 17px -8px rgba(170, 170, 170, 1);
      transition: all 0.5s ease-in-out;
    }

    .img-main {
      border-radius: 30px;
      height: 200px;
    }
    .price {
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 15px 15px 0 15px;
    }
  }

  .model-img {
    height: 70vh;
  }
}

@media (max-width: 700px) {
  .model-img {
    height: 40vh !important;
  }
}
